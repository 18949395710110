import { useState } from "react";

import { RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "./App.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";


export default function App() {
  const [index, setIndex] = useState(-1);

  const photos = [
    { src: "https://cdni.pornpics.com/1280/7/618/40113985/40113985_013_687e.jpg", width: 357, height: 537 },
    { src: "https://cdni.pornpics.com/1280/7/652/25007315/25007315_058_b365.jpg", width: 357, height: 537 },
    { src: "https://cdni.pornpics.com/1280/7/479/37873296/37873296_112_428d.jpg", width: 357, height: 537 },
    { src: "https://cdni.pornpics.com/1280/7/479/37873296/37873296_075_68db.jpg", width: 357, height: 537 },
    { src: "https://cdni.pornpics.com/1280/7/665/89070774/89070774_008_17f1.jpg", width: 357, height: 537 },
    { src: "https://cdni.pornpics.com/1280/1/385/21162291/21162291_015_d591.jpg", width: 357, height: 537 },
    { src: "https://cdni.pornpics.com/1280/7/612/43436910/43436910_051_2bc9.jpg", width: 302, height: 537 },
    { src: "https://cdni.pornpics.com/1280/7/161/61028405/61028405_009_00a4.jpg" , width: 853, height: 1280 },
    { src: "https://cdni.pornpics.com/1280/7/479/54235754/54235754_037_445e.jpg", width: 357, height: 537 },
    { src: "https://cdni.pornpics.com/1280/7/236/55203260/55203260_055_fc10.jpg", width: 802, height: 537 },
    { src: "https://cdni.pornpics.com/1280/7/686/26878517/26878517_003_55e7.jpg", width: 402, height: 537 },
    { src: "https://cdni.pornpics.com/460/7/664/11129543/11129543_087_548a.jpg", width: 305, height: 537 },
    { src: "https://cdni.pornpics.com/460/7/236/55203260/55203260_035_0309.jpg", width: 362, height: 537 },
    { src: "https://cdni.pornpics.com/460/7/574/95385222/95385222_098_69fa.jpg", width: 460, height: 816 },
    { src: "https://cdni.pornpics.com/460/7/661/66399440/66399440_114_8837.jpg", width: 460, height: 690 },
    { src: "https://cdni.pornpics.com/460/7/216/40552379/40552379_079_91a9.jpg", width: 460, height: 816 },
    { src: "https://cdni.pornpics.com/460/7/693/75278248/75278248_054_c3d4.jpg", width: 460, height: 690 },
    { src: "https://cdni.pornpics.com/460/7/587/13640602/13640602_071_a600.jpg", width: 460, height: 690 },
    { src: "https://cdni.pornpics.com/460/7/662/45984188/45984188_106_c8dd.jpg", width: 460, height: 690 },
    { src: "https://cdni.pornpics.com/460/7/36/49554035/49554035_362_8b7a.jpg", width: 460, height: 690 },
    { src: "https://cdni.pornpics.com/460/7/52/50577708/50577708_134_43ea.jpg", width: 460, height: 690 },
    { src: "https://cdni.pornpics.com/460/7/424/27933527/27933527_009_7e01.jpg", width: 460, height: 690},

  ];  

  return (
    <>
    <div className="header">ebonytube.pro</div>
    <div className="container">
      <div className="top-banner"></div>
      <p className="enlarge-text">Click an image to enlarge!</p>
      <RowsPhotoAlbum photos={photos} onClick={({ index }) => setIndex(index)} />
      <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </div> 
    <div className="footer">Copyright © ebonytube.pro</div> 
    </>
  );
}
